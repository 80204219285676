// @ts-ignore
import state from '../state.ts'
import axios from "axios";
import { watch } from 'vue'

const celium = axios.create();
celium.defaults.baseURL = process.env.VUE_APP_CELIUM_BASE_URL;

export default function doesCoverageCheck() {
    watch(state.geocodeResult, (newValue) => {
        state.loading.value = true

        const payload = {
            longitude: newValue.value.geometry.coordinates[0],
            latitude: newValue.value.geometry.coordinates[1],
            with_geojson: false,
            with_planned: true,
            source: 'customer',
            address: newValue.value.place_name
        };

        if (state.referrer.value) {
            payload['referrer'] = state.referrer.value
        }

        if (state.currentPage.value) {
            payload['current_page'] = state.currentPage.value
        }

        celium.post('prequalification/gis', payload).then((result) => {
            state.coverageCheckResult.value = result.data
        }).catch(() => {
            state.error.value = true
        }).finally(() => {
            state.loading.value = false
        })
    })
}
