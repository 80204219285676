// @ts-ignore
import {coverageCheckResult} from '../state.ts'
import { watch } from 'vue'


export default function redirectsOnInCoverage() {
    watch(coverageCheckResult, (newValue) => {
        if(newValue.value.in_coverage) {
            let network = newValue.value.ont_location ? 'fibre' : 'core';
            let lowUsage = newValue.value.show_low_usage_plan;

            let href = `${process.env.VUE_APP_SIGNUP_URL + newValue.value.jwt}?network=${network}`;
            if(lowUsage){
                href += '&low_usage=1';
            }

            window.top.location.href = href;
        }
    })

    return {};
}
