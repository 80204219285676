import { reactive, ref } from 'vue'

let geocodeResult = reactive({})
let coverageCheckResult = reactive({})
let loading = ref(false)
let error = ref(false)
let referrer = reactive({})
let currentPage = reactive({});

export { geocodeResult, coverageCheckResult, loading, error, referrer, currentPage }
export default { geocodeResult, coverageCheckResult, loading, error, referrer, currentPage }
